import Rails from "@rails/ujs";
import { Controller } from "stimulus";

export default class extends Controller {
  static values = { query: String };
  static targets = [
    "feed",
    "skeletonFeed",
    "aggregations",
    "aggregationsSkeleton",
    "collectionSize",
    "collectionSizeSkeleton",
    "emptyStateTemplate",
    "modalAggregations",
  ];

  connect() {
    this._hideElements([
      this.skeletonFeedTarget,
      this.aggregationsSkeletonTarget,
      this.collectionSizeSkeletonTarget,
    ]);
    this.queryParams = new URLSearchParams();
  }

  toggleSort(event) {
    // We don't fetch products if the sort params is the default one
    if (event.target.value === "default") return;

    this.queryParams.set("sort", event.target.value);

    this.queryValue = this.queryParams.toString();
    this._fetchProducts(this.queryParams, "sort");
  }

  // Everytime this method is executed, we get a filter parameter that allows us
  // to reduce the ElasticSearch collection size in order to find what we're searching
  // for more rapidly.

  // If I click once I should append the filter tuple to the filter array
  // If I click on an item that's already on the filter array I should remove it from the array
  // Everytime a filter is applied, we should make a request to the server to obtain the
  // and apply the filter by appending the key value pair to the product fetch request.
  filter(event) {
    event.preventDefault();
    const target = event.target.closest("a");

    let key = target.dataset.filterkey;
    let value = target.dataset.filtervalue;
    let modal = document.querySelector("#mobile-filter-modal");

    if (key.includes("price")) {
      this._filterByPrice(event.target);
    } else {
      if (this.queryParams.get(key) === value) {
        this.queryParams.delete(key);
      } else {
        this.queryParams.set(key, value);
      }
    }

    $(modal).modal("hide");
    this.queryValue = this.queryParams.toString();
    this._fetchProducts(this.queryParams, "filter");
  }

  _fetchProducts(params, type = "") {
    this._removeElements([this.feedTarget, this.collectionSizeTarget]);
    this._showElement(this.collectionSizeSkeletonTarget);
    this._showElement(this.skeletonFeedTarget);
    let url = new URL(window.location.href);

    if (type !== "sort") {
      this._removeElement(this.aggregationsTarget);
      this._showElements([
        this.skeletonFeedTarget,
        this.aggregationsSkeletonTarget,
        this.collectionSizeSkeletonTarget,
      ]);
    }

    console.log(url);
    Rails.ajax({
      url:
        url.search !== "" ? url.href + "&" + params : url.href + "?" + params,
      type: "GET",
      dataType: "json",
      success: (data) => {
        this.collectionSizeTarget.innerHTML = `${data.product_count} productos encontrados`;
        this._hideElements([
          this.collectionSizeSkeletonTarget,
          this.skeletonFeedTarget,
        ]);

        if (data.products.length > 0) {
          this.feedTarget.innerHTML = data.products;
        } else {
          this.feedTarget.innerHTML = this.emptyStateTemplateTarget.innerHTML;
        }

        if (type !== "sort") {
          this._hideElement(this.aggregationsSkeletonTarget);
          this.modalAggregationsTarget.innerHTML = data.aggregations;
          this.aggregationsTarget.innerHTML = data.aggregations;
        }
      },
    });
  }

  _removeElements(elements) {
    elements.forEach((el) => {
      this._removeElement(el);
    });
  }

  _removeElement(el) {
    el.innerHTML = "";
  }

  _hideElements(elements) {
    elements.forEach((el) => {
      this._hideElement(el);
    });
  }

  _showElements(elements) {
    elements.forEach((el) => {
      this._showElement(el);
    });
  }

  _hideElement(element) {
    element.classList.add("hidden");
  }

  _showElement(element) {
    element.classList.remove("hidden");
  }

  _filterByPrice(target) {
    let priceFrom = target.dataset.priceFrom;
    let priceTo = target.dataset.priceTo;

    if (priceFrom && priceTo) {
      if (
        this.queryParams.get("price_from") === priceFrom &&
        this.queryParams.get("price_to") === priceTo
      ) {
        this.queryParams.delete("price_from");
        this.queryParams.delete("price_to");
      } else {
        this.queryParams.set("price_from", priceFrom);
        this.queryParams.set("price_to", priceTo);
      }
    } else if (priceFrom !== undefined) {
      if (this.queryParams.get("price_from") === priceFrom) {
        this.queryParams.delete("price_from");
      } else {
        this.queryParams.set("price_from", priceFrom);
        this.queryParams.delete("price_to");
      }
    } else if (priceTo != undefined) {
      if (this.queryParams.get("price_to") === priceTo) {
        this.queryParams.delete("price_to");
      } else {
        this.queryParams.set("price_to", priceTo);
        this.queryParams.delete("price_from");
      }
    }
  }
}
